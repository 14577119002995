const state = {
    recCode: ''
}
const getters = {

}
const actions = {

}
const mutations = {
    SET_RECCODE: (state , code) => {
        state.recCode=code
        localStorage.setItem('recCode',code)
    },
    SET_ITEMCODE: (state , code) => {
        localStorage.setItem('itemCode',code)
    },
    SET_SIGHT:(state,code)=>{
        localStorage.setItem('itemSight',code)
    },
    SET_PD_LEN:(state,code)=>{
        localStorage.setItem('pdLen',code)
    },
    SET_FOCAL_LEN:(state,code)=>{
        localStorage.setItem('focalLen',code)
    },
    SET_SEX:(state,code)=>{
        localStorage.setItem('sex',code)
    }
}

const store_modular = {
    namespaced: true,//开启命名空间，命名空间是为了解决方法可能出现命名重复的问题
    state,
    getters,
    actions,
    mutations
}
export default store_modular
