<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import * as faceapi from "@vladmandic/face-api";
import store_modular from "@/store/store_modular";
import Vue from 'vue';

export default {
  mounted() {
    this.fnLoadModel()
  },
  methods:{
    /**初始化模型加载 */
    async  fnLoadModel() {

      // 模型文件访问路径
      const modelsPath = `/models`;
      // 面部轮廓模型
      await faceapi.nets.faceLandmark68Net.load(modelsPath);
      // 面部表情模型
      await faceapi.nets.faceExpressionNet.load(modelsPath);
      // 年龄性别模型
      // await faceapi.nets.ageGenderNet.load(modelsPath);

      // 模型参数-ssdMobilenetv1
      await faceapi.nets.ssdMobilenetv1.load(modelsPath);
      Vue.prototype.$ssdMobilenetv1 = new faceapi.SsdMobilenetv1Options({
        minConfidence: 0.5, // 0 ~ 1
        maxResults: 50, // 0 ~ 100
      });
      // 模型参数-tinyFaceDetector
      await faceapi.nets.tinyFaceDetector.load(modelsPath);
      Vue.prototype.$tinyFaceDetector = new faceapi.TinyFaceDetectorOptions({
        inputSize: 416, // 160 224 320 416 512 608
        scoreThreshold: 0.5, // 0 ~ 1
      });

      // 输出库版本
      console.log(
          `FaceAPI Version: ${
              faceapi?.version || "(not loaded)"
          } \nTensorFlow/JS Version: ${
              faceapi.tf?.version_core || "(not loaded)"
          } \nBackend: ${
              faceapi.tf?.getBackend() || "(not loaded)"
          } \nModels loaded: ${faceapi.tf.engine().state.numTensors} tensors`
      );


    }
  }
}
</script>

<!-- 
<style lang="scss" src="./assets/css/uni.scss"></style>
<style lang="scss" src="./assets/css/common.scss"></style> -->


<style lang="less">
body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, /* structural elements 结构元素 */
dl, dt, dd, ul, ol, li, /* list elements 列表元素 */
pre, /* text formatting elements 文本格式元素 */
fieldset, lengend, button, input, textarea, /* form elements 表单元素 */
th, td {
  /* table elements 表格元素 */
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.5); 
}

</style>
