import Vue from 'vue'
import Vuex from 'vuex'
import store_modular from '@/store/store_modular/index.js'
Vue.use(Vuex)


export default new Vuex.Store({
  modules: {
    store_modular
  }
})
